import {
  Hero,
  Indicators,
  InitiativesPartners,
  Meta,
  ViewData,
} from "@components/pages/data";
import {  } from "@functions/i18n/get-static-paths";
import { makeStaticProps } from "@functions/i18n/make-static-props";
import { useRouterQuery } from "@hooks";
import { Box, Container } from "@mantine/core";
import { HomeQueryParams } from "@models/home-query-params";
import { isRateAllowed } from "@utils";
import { QueryKeys } from "@enums";
import dynamic from "next/dynamic";

const GuidedTour = dynamic(
  () => import("@components/pages/data").then((mod) => mod.GuidedTour),
  { ssr: false }
);

const Data = () => {
  const {
    query: { indicator, measure },
    replaceQuery,
  } = useRouterQuery<HomeQueryParams>();

  // * Instead of this, we should return 404 if query
  // * has wrong measure, and switching bw indicators
  // * will automatically switch measure too
  if (!isRateAllowed(indicator) && measure === "rate") {
    replaceQuery({ measure: "count" });
  }

  return (
    <Box>
      <Meta />
      <GuidedTour />
      <Hero />
      <Indicators />
      <ViewData />
      <Container>
        <InitiativesPartners />
      </Container>
    </Box>
  );
};

export default Data;

const getStaticProps = makeStaticProps(
  ["data", "notifications", "common", "data-sources"],
  [QueryKeys.Areas, QueryKeys.Diseases]
);
export { getStaticProps };
